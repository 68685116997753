// src/components/Home.js
import React from 'react';
import { BackendProvider, WorkspaceProvider } from "@gooddata/sdk-ui";
import { backend } from "./backend.js";
import { Dashboard } from "@gooddata/sdk-ui-dashboard";
import { idRef } from "@gooddata/sdk-model";

function Home() {
    const params = new URLSearchParams(document.location.search);
    // To get a specific query parameter
    const WORKSPACEID = params.get('workspace');
    const DASHBOARDID = params.get('dashboard')


    const dashboardRef = idRef(DASHBOARDID);

    return (
        (WORKSPACEID && DASHBOARDID) ? <BackendProvider backend={backend}>
            <WorkspaceProvider workspace={WORKSPACEID}>
                <Dashboard dashboard={dashboardRef} />
            </WorkspaceProvider>
        </BackendProvider> : <h1>Please Provide Workspace and dashboard ID</h1>
    );
}


export default Home;
