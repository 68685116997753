// src/components/Visualization.js
import React, { useEffect, useRef } from 'react';

function Visualization() {

    const params = new URLSearchParams(document.location.search);
    const WORKSPACEID = params.get('workspace');

    const iframeRef = useRef(null);

    const host = process.env.REACT_APP_GOOD_DATA_HOST_NAME
    const token = process.env.REACT_APP_TIGER_API_TOKEN

    
    useEffect(() => {

      const handlePostMessage = (event) => {
        console.log("Post message received", event);
  
        if (event.data.gdc?.event.name === "listeningForApiToken") {
  
          const postMessageStructure = {
            gdc: {
              product: "analyticalDesigner",
              event: {
                name: "setApiToken",
                data: {
                  token: token,
                },
              },
            },
          };
          console.log("Sending token to embedded window");
  
          const origin = "*";
          iframeRef.current.contentWindow.postMessage(postMessageStructure, origin);
        }
      };
  
      console.log("Setup parent frame message listener");
      window.addEventListener("message", handlePostMessage, false);
  
      return () => {
        window.removeEventListener("message", handlePostMessage);
      };
    }, []);
  
    return (
      host && WORKSPACEID && <iframe
        title="Embed GD Application"
        id="embedded-app-frame"
        src={`${host}analyze/embedded/#/${WORKSPACEID}?apiTokenAuthentication=true`}
        width="1300"
        height="800"
        style={{border: "none"}}
        ref={iframeRef}
      />
    );
}

export default Visualization;
