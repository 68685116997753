// (C) 2019-2023 GoodData Corporation
import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Visualization from './components/Visualization';



export const App = () => {
    return (
        <Router>
          <div>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/visualization" element={<Visualization />} />
            </Routes>
          </div>
        </Router>
    );
};
